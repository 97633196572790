import Spacer from "../Spacer";
import { Typography } from "@mui/material";
import css from "../../styles/ProductDetailsHeader.module.css";

const ProductDetailsHeader = ({ data }) => {
  const { name, disabled, oldName } = data || {};
  return (
    <>
      <h4 className={css.title}>
        {name} {disabled && <b>*</b>}
      </h4>
      <Spacer height={5} />
      {oldName && (
        <Typography variant="body2" className={css.oldName}>
          ({`${oldName}`})
        </Typography>
      )}
    </>
  );
};

export default ProductDetailsHeader;
