import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import RefreshJwt from "./components/RefreshJwt";
import Loader from "./components/Loader";
import GlobalLoader from "./components/GlobalLoader";
import ScrollToTop from "./components/ScrollToTop";
import RegenRewardReferralsPage from "./pages_web/RegenRewardReferralsPage";
import ProductQuickLookPage from "./pages/ProductQuickLookPage";
import CartMain from "./components/cart/CartMain";
import CookieNotice from "./components_web/CookieNotice";
import { OldBaseRoute } from "./routes/RegexRoute";
import lazyWithRetry from "./utils/lazy";
import Chatbot from "./components/chatbot/Chatbot";
import RecommendationByAdminPage from "./pages/RecommendationByAdminPage";
const GoogleAnalytics = lazyWithRetry(() =>
  import("./components/GoogleAnalytics")
);
const HotjarHeatMap = lazyWithRetry(() => import("./components/HotjarHeatMap"));
const AdminRoutes = lazyWithRetry(() => import("./routes/AdminRoutes"));
const ProRoutes = lazyWithRetry(() => import("./routes/ProRoutes"));
const RetailRoutes = lazyWithRetry(() => import("./routes/RetailRoutes"));
const SchoolRoutes = lazyWithRetry(() => import("./routes/SchoolRoutes"));
const WebRoutes = lazyWithRetry(() => import("./routes/WebRoutes"));
const PageNotFound = lazyWithRetry(() => import("./components/PageNotFound"));
const Footer = lazyWithRetry(() => import("./components/Footer"));
const Header = lazyWithRetry(() => import("./components_web/Header"));
const ProductDetails = lazyWithRetry(() =>
  import("./components/ProductDetails/ProductDetails")
);
const AcceptTermsAndCondition = lazyWithRetry(() =>
  import("./components/AcceptTermsAndCondition")
);
const LaunchStorePage = lazyWithRetry(() => import("./pages/LaunchStorePage"));
const ImpersonationPage = lazyWithRetry(() =>
  import("./pages/ImpersonationPage")
);
const RecommendationPage = lazyWithRetry(() =>
  import("./pages/RecommendationPage")
);
const DaysOfGlyMed = lazyWithRetry(() => import("./pages/DaysOfGlyMed"));
const ProtectedRoutes = lazyWithRetry(() => import("./routes"));

function App() {
  const location = useLocation();
  const { backgroundLocation } = location.state || {};
  return (
    <Suspense fallback={<Loader style={{ minHeight: "100vh" }} />}>
      <div className="App">
        <Header />
        <CookieNotice />
        <Routes location={backgroundLocation || location}>
          {/* ==Old Route Redirect== */}
          {OldBaseRoute()}
          {/* ======ENd======= */}

          <Route path="*" element={<WebRoutes />} />
          <Route path="b2b/*" element={<ProRoutes />} />
          <Route path="retail/*" element={<RetailRoutes />} />
          <Route path="school/*" element={<SchoolRoutes />} />
          <Route path="admin/*" element={<AdminRoutes />} />
          <Route path="products/:productId" element={<ProductDetails />} />
          <Route path="cart" element={<CartMain />} />
          <Route
            path="reward-referral"
            element={<RegenRewardReferralsPage />}
          />
          <Route path="launch/:storeLabel" element={<LaunchStorePage />} />
          <Route path="store/:storeLabel" element={<LaunchStorePage />} />
          <Route path="impersonate/:id" element={<ImpersonationPage />} />
          <Route
            path="recommendations/:bundleToken/:accountId"
            element={<RecommendationPage />}
          />
          <Route
            path="bundle/:bundleToken"
            element={<RecommendationByAdminPage />}
          />
          <Route path="12-days-of-glymed" element={<ProtectedRoutes salesChannel="common"><DaysOfGlyMed /></ProtectedRoutes>} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {backgroundLocation ? (
          <Routes>
            <Route path="products/:sku" element={<ProductQuickLookPage />} />
            <Route path="cart" element={<CartMain />} />
          </Routes>
        ) : null}
        <Chatbot />
        <Footer />
        <RefreshJwt />
        <GlobalLoader />
        <ScrollToTop />
        <AcceptTermsAndCondition />
        {process.env.REACT_APP_APP_MODE === "production" && <HotjarHeatMap />}
        {process.env.REACT_APP_APP_MODE === "production" && <GoogleAnalytics />}
      </div>
    </Suspense>
  );
}

export default App;
