import React from "react";
import { Box, Typography } from "@mui/material";
import { getFormattedPrice } from "../../utils";
import css from "../../styles/SkuInfo.module.css";

const SkuPriceWithDiscount = ({ sku }) => {
  return (
    <Box>
      {sku?.discountAmount > 0 ? (
        <Box className={css.discountBox}>
          <Typography variant="h4">
            {`$${getFormattedPrice(sku.discountedAmount, 2)}`}
          </Typography>
          <Typography sx={{ ml: 1.25, p: 0 }} variant="h5" color="error">
            <strike>${getFormattedPrice(sku.price, 2)}</strike>
          </Typography>
        </Box>
      ) : (
        <Typography variant="h4">
          {`$${getFormattedPrice(sku.price, 2)}`}
        </Typography>
      )}

      <Typography className={css.productSize} variant="h6">
        {sku.sku} ({sku.productSize})
      </Typography>
    </Box>
  );
};
export default SkuPriceWithDiscount;
