import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useRecoilState } from "recoil";
import { GlyMedSnackbarState } from "../atoms/globalAtom";

const GlyMedSnackbar = () => {
  const [snackbarState, setSnackbarState] = useRecoilState(GlyMedSnackbarState);
  const { message, severity, open } = snackbarState;

  const handleClose = () => {
    setSnackbarState({ message: "", severity: "success", open: false, });
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="standard"
        componentsProps={{
          closeButton: {
            sx: {
              height: "auto"
            },
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default GlyMedSnackbar;
